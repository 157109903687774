import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "43",
  height: "43",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M37.136 0H5.864A5.864 5.864 0 0 0 0 5.864v31.272A5.864 5.864 0 0 0 5.864 43h31.272A5.864 5.864 0 0 0 43 37.136V5.864A5.864 5.864 0 0 0 37.136 0ZM14.66 34.029a.907.907 0 0 1-.906.908H9.89a.908.908 0 0 1-.909-.908V17.835a.909.909 0 0 1 .909-.909h3.863a.91.91 0 0 1 .906.91v16.193Zm-2.84-18.637a3.665 3.665 0 1 1 0-7.33 3.665 3.665 0 0 1 0 7.33Zm23.03 18.7a.831.831 0 0 1-.836.836h-4.153a.832.832 0 0 1-.836-.836v-7.586c0-1.134.332-4.965-2.964-4.965-2.553 0-3.073 2.622-3.176 3.8v8.76a.836.836 0 0 1-.823.837H18.05a.832.832 0 0 1-.833-.836V17.764a.834.834 0 0 1 .833-.835h4.012a.836.836 0 0 1 .835.835v1.413c.949-1.425 2.353-2.52 5.351-2.52 6.64 0 6.597 6.201 6.597 9.607l.005 7.828Z",
      fill: "#B2B3D5"
    }, null, -1)
  ])))
}
export default { render: render }