import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M21.59 0H3.41A3.41 3.41 0 0 0 0 3.41v18.18A3.409 3.409 0 0 0 3.41 25h18.18A3.41 3.41 0 0 0 25 21.59V3.41A3.41 3.41 0 0 0 21.59 0ZM8.524 19.784a.527.527 0 0 1-.527.529H5.75a.528.528 0 0 1-.528-.529V10.37a.528.528 0 0 1 .528-.528h2.246a.528.528 0 0 1 .527.528v9.415ZM6.873 8.95a2.13 2.13 0 1 1 0-4.261 2.13 2.13 0 0 1 0 4.26ZM20.26 19.82a.485.485 0 0 1-.485.486H17.36a.483.483 0 0 1-.486-.486v-4.41c0-.66.193-2.887-1.723-2.887-1.484 0-1.787 1.524-1.847 2.209v5.094a.486.486 0 0 1-.478.485h-2.333a.485.485 0 0 1-.484-.485v-9.499a.486.486 0 0 1 .484-.486h2.333a.486.486 0 0 1 .486.486v.821c.55-.828 1.367-1.464 3.11-1.464 3.861 0 3.836 3.605 3.836 5.585l.002 4.551Z",
      fill: "#B2B3D5"
    }, null, -1)
  ])))
}
export default { render: render }