import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "44",
  height: "31",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M42.674 4.864a5.396 5.396 0 0 0-3.778-3.836C35.524.084 22.014.084 22.014.084A129.642 129.642 0 0 0 5.154.98a5.6 5.6 0 0 0-3.8 3.882 58.809 58.809 0 0 0-.876 10.476c-.022 3.51.27 7.017.876 10.476a5.457 5.457 0 0 0 3.8 3.836c3.416.942 16.86.942 16.86.942 5.64.068 11.278-.231 16.882-.896a5.396 5.396 0 0 0 3.777-3.837c.62-3.453.92-6.962.9-10.477a53.727 53.727 0 0 0-.899-10.52v.002ZM17.72 21.867V8.81l11.24 6.529-11.24 6.527Z",
      fill: "#474996",
      "fill-opacity": ".4"
    }, null, -1)
  ])))
}
export default { render: render }