import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "45",
  height: "46",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M0 22.835c0 11.29 8.124 20.677 18.75 22.581v-16.4h-5.625v-6.308h5.625v-5.047c0-5.677 3.624-8.83 8.75-8.83 1.624 0 3.376.252 5 .504v5.804h-2.875c-2.75 0-3.375 1.387-3.375 3.154v4.415h6l-1 6.307h-5v16.401C36.876 43.512 45 34.126 45 22.835 45 10.275 34.875 0 22.5 0S0 10.275 0 22.835Z",
      fill: "#474996",
      "fill-opacity": ".4"
    }, null, -1)
  ])))
}
export default { render: render }