import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "17",
  height: "17",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("ellipse", {
      cx: "8.452",
      cy: "8.5",
      rx: "8.452",
      ry: "8.5",
      transform: "rotate(-180 8.452 8.5)",
      fill: "#fff"
    }, null, -1),
    _createElementVNode("path", {
      d: "M8.544 12.222c.601 0 .902.3.902.898 0 .587-.3.88-.902.88-.601 0-.902-.293-.902-.88 0-.599.3-.898.902-.898Zm0-8.502c.454 0 .68.263.68.79v4.928c0 .515-.226.772-.68.772-.465 0-.698-.257-.698-.772V4.51c0-.527.233-.79.698-.79Z",
      fill: "#FF385C"
    }, null, -1)
  ])))
}
export default { render: render }