import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "26",
  height: "19",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M25.145 3.652a3.2 3.2 0 0 0-2.246-2.28C20.895.81 12.863.81 12.863.81a77.073 77.073 0 0 0-10.024.533A3.329 3.329 0 0 0 .58 3.651 34.962 34.962 0 0 0 .06 9.88a34.899 34.899 0 0 0 .52 6.228 3.244 3.244 0 0 0 2.259 2.28c2.031.56 10.024.56 10.024.56a77.223 77.223 0 0 0 10.036-.532 3.209 3.209 0 0 0 2.245-2.28 34.04 34.04 0 0 0 .535-6.23 31.947 31.947 0 0 0-.534-6.254v.001ZM10.31 13.76V6l6.683 3.881-6.683 3.88Z",
      fill: "#474996",
      "fill-opacity": ".4"
    }, null, -1)
  ])))
}
export default { render: render }