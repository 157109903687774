import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "28",
  height: "27",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M.742 13.575c0 6.712 4.83 12.293 11.147 13.425v-9.75H8.545V13.5h3.344v-3c0-3.375 2.155-5.25 5.202-5.25.966 0 2.007.15 2.972.3V9h-1.709c-1.635 0-2.006.825-2.006 1.875V13.5h3.567l-.594 3.75h-2.973V27c6.317-1.132 11.147-6.712 11.147-13.425C27.495 6.11 21.475 0 14.118 0 6.762 0 .742 6.109.742 13.575Z",
      fill: "#474996",
      "fill-opacity": ".4"
    }, null, -1)
  ])))
}
export default { render: render }